import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';

const apiURL = process.env.REACT_APP_API_URL;
const Cart = () => {
  const {removeFromCart} = useContext(AuthContext)
   const [cartItems, setCartItems] = useState([]);
  const [errors, setError] = useState('');
  const [subtotal, setSubtotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getCartItems();
  }, []);

  const getCartItems = async () => {
    try {
      const res = await axios.get(`${apiURL}/api/viewcart`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        },
      });
      setCartItems(res.data);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    if (cartItems.length > 0) {
      const total = cartItems.reduce((acc, item) => acc + (item.product?.price * item.quantity), 0);
      setSubtotal(total);
      setLoading(false);
    }
  }, [cartItems]);

  const updateCartItem = async (itemId, quantity) => {
    try {
      await axios.post(`${apiURL}/api/addcart`, { itemId, quantity }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        },
      });
      getCartItems(); // Refresh cart items after update
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleIncrement = (itemId, currentQuantity) => {
    const newQuantity = currentQuantity + 1;
    updateCartItem(itemId, newQuantity);
  };

  const handleDecrement = (itemId, currentQuantity) => {
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1;
      updateCartItem(itemId, newQuantity);
    }
  };

  // if (loading) {
  //   return <div className="text-center">Loading...</div>;
  // }
  const removeCartItem = (id) => {
    removeFromCart(id)
  }

  const checkout = () =>{
    navigate('/checkout');
   
  }

  return (
    <div className="bg-gray-100 pt-20">
      <h1 className="mb-10 text-center text-2xl font-bold">Cart Items</h1>
      <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
        {cartItems.length > 0 ? (
          <>
            <div className="rounded-lg md:w-2/3">
              {cartItems.map((item) => (
                <div key={item._id} className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
                  <img
                    src={item.product?.images[0]}
                    alt="product-image"
                    className="w-full rounded-lg sm:w-40"
                  />
                  <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                    <div className="mt-5 sm:mt-0">
                      <h2 className="text-lg font-bold text-gray-900">{item.product?.name}</h2>
                      <p className="mt-1 text-xs text-gray-700">Price: ₹{item.product?.price}</p>
                    </div>
                    <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                      <div className="flex items-center border-gray-100">
                        <button

                          className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"
                        >
                          {' '} - {' '}
                        </button>
                        <input
                          className="h-8 w-8 border bg-white text-center text-xs outline-none"
                          type="number"
                          value={item.quantity}
                          readOnly
                        />
                        <button

                          className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"
                        >
                          {' '} + {' '}
                        </button>
                      </div>
                      <div className="flex items-center space-x-4">
                        <p className="text-sm">Total cost: ₹{item.product?.price * item.quantity}</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500"
                          onClick={() => removeCartItem(item._id)}
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Sub total */}
            <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
              <div className="mb-2 flex justify-between">
                <p className="text-gray-700">Subtotal</p>
                <p className="text-gray-700">₹{subtotal}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-700">Shipping</p>
                <p className="text-gray-700">₹199</p>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold">Total</p>
                <p className="mb-1 text-lg font-bold">₹{subtotal + 199}</p>
              </div>
              <button onClick={checkout} className="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">
                Check out
              </button>
            </div>
          </>
        ) : (
          <div className="text-center">
           
                Your cart is empty. <Link to="/products">Continue shopping</Link></div>
        )}
          </div>
   </div>
      );
};

      export default Cart;
