"use client"
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

const apiURL = process.env.REACT_APP_API_URL; // Ensure this is the correct URL to your backend

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(Cookies.get('token'));
    const [loading, setLoading] = useState(true);
    const [errors, setError] = useState('');
    const [authMsg, setAuthMsg] = useState('');
    const [authErr, setAuthErr] = useState('');
    const [messages, setMessages] = useState('');
    const [cartItem, setCartItem] = useState("");

    useEffect(() => {
        if (token) {
            fetchProfile();
        } else {
            setLoading(false);
        }
    }, [token]);

    useEffect(()=>{
        if(authMsg){
            const timer = setTimeout(()=>{
                setAuthMsg();
            },5000)

            return () => clearTimeout(timer) 
        }
    },[authMsg])

    useEffect(()=>{
        if(authErr){
            const timer = setTimeout(()=>{
                setAuthErr();
            },5000)

            return () => clearTimeout(timer) 
        }
    },[authErr])

    useEffect(() => {
        if (errors) {
            const timer = setTimeout(() => {
                setError('');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [errors]);

    useEffect(() => {
        if (messages) {
            const timer = setTimeout(() => {
                setMessages('');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [messages]);

    useEffect(()=>{
        getCartItems()
    },[])

    const register = async (name, email, password, phone) => {
        try {
            const res = await axios.post(`${apiURL}/api/auth/signup`, { name, email, password,phone });
            setToken(res.data.token);
            Cookies.set('token', res.data.token);
            await fetchProfile();
            window.location.href = '/signin';
        } catch (error) {
            console.error(error);
            setAuthErr(error.response.data.message);
            
        }
    };

    const login = async (email, password) => {
        try {
            const res = await axios.post(`${apiURL}/api/auth/signin`, { email, password });
            setToken(res.data.token);
            Cookies.set('token', res.data.token);
            await fetchProfile();
            window.location.href = '/';
        } catch (error) {
            console.error(error);
            setAuthErr(error.response.data.message);
          
        }
    };

    const fetchProfile = async () => {
        try {
            const res = await axios.get(`${apiURL}/api/auth/profile`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setUser(res.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const logout = () => {
        setToken(null);
        setUser(null);
        Cookies.remove('token');
        window.location.href = '/';
    };

    const forgotPassword = async (email) => {
        try {
            const res = await axios.post(`${apiURL}/api/auth/forgot-password`, { email });
            setAuthMsg(res.data.message);
            
        } catch (error) {
            console.error(error);
            setAuthErr(error.response.data.message);
     
        }
    };

    const resetPassword = async (token, password) => {
        try {
            const res = await axios.post(`${apiURL}/api/auth/reset-password`, { token, newPassword: password });
            setAuthMsg(res.data.message);
           
        } catch (error) {
            console.error(error);
            setAuthErr(error.response.data.message);
           
        }
    };

    // Cart functionality
    const addToCart = async (productId, quantity) => {
        try {
            const res = await axios.post(`${apiURL}/api/addcart`, { productId, quantity }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log(res.data);
            toast.success('Item added to cart!');
        } catch (error) {
            console.error(error);
            toast.error('Please Login and add product in cart!');

        }
    };

    const removeFromCart = async (id) => {
        try {
            const res = await axios.delete(`${apiURL}/api/deletecart/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log(res.data);
            window.location.reload()
        } catch (error) {
            console.error(error);
            setError(error.response.data.message);
        }
    };

    const getCartItems = async () => {
        try {
            const res = await axios.get(`${apiURL}/api/viewcart`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            console.log(res.data);
            setCartItem(res.data)
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };

    console.log("User Cart Items : ", cartItem)

    return (
        <AuthContext.Provider value={{
            user,
            token,
            loading,
            errors,
            messages,
            register,
            login,
            logout,
            authMsg,
            authErr,
            forgotPassword,
            resetPassword,
            addToCart,
            removeFromCart,
            getCartItems,
            cartItem,
            isAuthenticated: !!user
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
