import React, { useState } from 'react';
import axios from 'axios';


const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneno: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.arrivalsoutions.in/contactus.php', formData);
            console.log(response.data);
            alert('Message sent successfully!');
            // Clear form fields after successful submission (optional)
            setFormData({
                name: '',
                email: '',
                phoneno: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message. Please try again.');
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-6 md:flex justify-between items-start bg-white rounded-lg shadow-lg my-8">
            {/* Contact Information (Left Side) */}
            <div className="md:w-1/2 mb-8 md:mb-0 p-6 pr-12 relative">
                <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
                <p className="mb-2">Address: Om Shree Vrindavan Apartment, Beside Ram Kutir Soc, Ramdev Park, Mira-Rd East, Mumbai-401107</p>
                <p className="mb-2">Phone: +91 7756048751, +91 7718006913</p>
                <p className="mb-2">Email: contact@pehell.com</p>
                <div className="mt-4 space-x-4">
                    {/* Social Links */}
                    <a href="#" className="text-blue hover:text-blue-700">
                        <i className='bi bi-facebook'></i>
                    </a>
                    <a href="#" className="text-blue hover:text-blue-700">
                        <i className='bi bi-twitter'></i>
                    </a>
                    <a href="https://www.instagram.com/pehell.in?igsh=YnZzMjRsOWFyNmd1" className="text-blue hover:text-blue-700">
                        <i className='bi bi-instagram'></i>
                    </a>
                </div>
                {/* Vertical Divider */}
                <div className="absolute right-7 bg-gray-300 w-px h-full"></div>
            </div>

            {/* Contact Form (Right Side) */}
            <form className="md:w-2/3" onSubmit={handleSubmit}>
                <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                <div className="grid grid-cols-1 gap-4">
                    <div>
                        <label htmlFor="name" className="block font-medium text-gray-700">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block font-medium text-gray-700">
                            Phone
                        </label>
                        <input
                            type="tel"
                            id="phoneno"
                            name="phoneno"
                            value={formData.phoneno}
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="subject" className="block font-medium text-gray-700">
                            Subject
                        </label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="block font-medium text-gray-700">
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={formData.message}
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="bg-btn text-white py-3 px-6 rounded-md hover:bg-blue-600 transition duration-300"
                        >
                            Send Message
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;