import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../context/AuthContext';

const Product = () => {
  const { addToCart } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sizeFilter, setSizeFilter] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product`);
        setProducts(response.data);
        setFilteredProducts(response.data);
        // Assuming products have a price field to determine the max price
        const prices = response.data.map(product => product.price);
        setMinPrice(Math.min(...prices));
        setMaxPrice(Math.max(...prices));
        setPriceRange([Math.min(...prices), Math.max(...prices)]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [sizeFilter, priceRange, searchTerm, products]);

  const applyFilters = () => {
    let updatedProducts = products;

    // Filter by size
    if (sizeFilter) {
      updatedProducts = updatedProducts.filter(product => product.size === parseInt(sizeFilter));
    }

    // Filter by price range
    updatedProducts = updatedProducts.filter(product =>
      product.price >= priceRange[0] && product.price <= priceRange[1]
    );

    // Search filter
    if (searchTerm) {
      updatedProducts = updatedProducts.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredProducts(updatedProducts);
  };

  const handleAddToCart = (productId) => {
    addToCart(productId, 1); 
    
  };

  const handlePriceRangeChange = (e) => {
    setPriceRange([+e.target.value[0], +e.target.value[1]]);
  };

  const clearFilters = () => {
    setSizeFilter('');
    setPriceRange([minPrice, maxPrice]);
    setSearchTerm('');
  };

  return (
    <div className="container mx-auto flex">
      <div className="w-1/4 p-4">
        {/* Search Bar */}
        <div className="mb-4">
          <label htmlFor="search" className="block text-lg font-medium">Search:</label>
          <input
            id="search"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search products"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Size Filter */}
        <div className="mb-4">
          <label htmlFor="sizeFilter" className="block text-lg font-medium">Age:</label>
          <select
            id="sizeFilter"
            value={sizeFilter}
            onChange={(e) => setSizeFilter(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          >
            <option value="">All Age</option>
            <option value="1">0 - 1 Year</option>
            <option value="2">1 - 2 Years</option>
            <option value="3">2 - 5 Years</option>
            <option value="4">6+ Years</option>
          </select>
        </div>

        {/* Price Range Slider */}
        <div className="mb-4">
          <label className="block text-lg font-medium">Price:</label>
          <div className="flex items-center">
            <input
              type="range"
              min={minPrice}
              max={maxPrice}
              value={priceRange[0]}
              onChange={(e) => setPriceRange([+e.target.value, priceRange[1]])}
              className="mr-2"
            />
            <input
              type="range"
              min={minPrice}
              max={maxPrice}
              value={priceRange[1]}
              onChange={(e) => setPriceRange([priceRange[0], +e.target.value])}
              className="ml-2"
            />
          </div>
          <div className="flex justify-between mt-2">
            <input
              type="number"
              min={minPrice}
              max={maxPrice}
              value={priceRange[0]}
              onChange={(e) => setPriceRange([+e.target.value, priceRange[1]])}
              className="w-1/2 border-gray-300 rounded-md shadow-sm p-2"
            />
            <input
              type="number"
              min={minPrice}
              max={maxPrice}
              value={priceRange[1]}
              onChange={(e) => setPriceRange([priceRange[0], +e.target.value])}
              className="w-1/2 border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
        </div>

        {/* Clear Filters Button */}
        <button
          onClick={clearFilters}
          className="bg-red-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-red-600"
        >
          Clear Filters
        </button>
      </div>

      <div className="w-3/4 p-4">
        <h1 className="text-2xl heading-text font-semibold mt-8 mb-4">Our Products</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredProducts.length === 0 ? (
            <p>No products found.</p>
          ) : (
            filteredProducts.map((product) => (
              <motion.div
                key={product._id}
                className="bg-white rounded-lg shadow-md overflow-hidden"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
              >
                <Carousel showArrows={true} showThumbs={false} infiniteLoop={true} autoPlay={false}>
                  {product.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={product.name}
                        className="w-full h-48 object-cover"
                      />
                    </div>
                  ))}
                </Carousel>
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">{product.name}</h2>
                  <div className="mt-4 text-right">
                    <button 
                      className="bi bi-cart3 text-lg font-extrabold bg-btn hover:bg-orange-600 text-white py-2 px-6 rounded-lg shadow-lg"
                      onClick={() => handleAddToCart(product._id)}
                    >
                     
                    </button>
                    <Link key={product._id} to={`/product/${product._id}`}>
                      <i className="bi bi-eye text-lg font-extrabold bg-btn hover:bg-orange-600 text-white py-2 px-6 rounded-lg shadow-lg mx-3"></i>
                    </Link>
                  </div>
                </div>
              </motion.div>
            ))
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Product;
