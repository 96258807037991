import React from "react";
const TopBar = () =>{
    return(
    
        <>
        <h3  className="topBar">Welcome To Pehell Family | Free Shipping Pan India </h3>
        </>
    )
}

export default TopBar;