import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../context/AuthContext';

const ProductList = () => {
  const { size } = useParams(); // Extract size from URL parameters
  const { addToCart } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product`);
        setProducts(response.data);
        filterProducts(response.data); // Apply filter after fetching data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [size]); // Fetch data again if size changes

  const filterProducts = (products) => {
    if (size) {
      const filtered = products.filter(product => product.size === parseInt(size)); // Ensure size is compared as integer
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  };

  const handleAddToCart = (productId) => {
    addToCart(productId, 1); // Add one quantity of the product to the cart
    toast.success('Item added to cart!');
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-2xl heading-text font-semibold mt-8 mb-4">Our Products</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {filteredProducts.length === 0 ? (
          <p>No products found for this size.</p> // Changed 'age' to 'size'
        ) : (
          filteredProducts.map((product) => (
            <motion.div
              key={product._id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <Carousel showArrows={true} showThumbs={false} infiniteLoop={true} autoPlay={false}>
                {product.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={product.name}
                      className="w-full h-48 object-cover"
                    />
                  </div>
                ))}
              </Carousel>
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">{product.name}</h2>
                <div className="mt-4 text-right">
                  <button 
                    className="bi bi-cart3 text-lg font-extrabold bg-btn hover:bg-orange-600 text-white py-2 px-6 rounded-lg shadow-lg"
                    onClick={() => handleAddToCart(product._id)}
                  >
                   
                  </button>
                  <Link key={product._id} to={`/product/${product._id}`}>
                    <i className="bi bi-eye text-lg font-extrabold bg-btn hover:bg-orange-600 text-white py-2 px-6 rounded-lg shadow-lg mx-3"></i>
                  </Link>
                </div>
              </div>
            </motion.div>
          ))
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductList;
