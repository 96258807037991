import axios from 'axios';
import { useScroll } from 'framer-motion';
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Replace Next.js Link with React Router Link

const Footer = () => {
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await axios.post('https://api-pehell.vercel.app/api/subscribe/', {
        email: email
      })
      setMsg(res.data.message)
    } catch (error) {
      console.log("Error", error)
    }
  }
  return (
    <footer className="bg-gray-800 text-white mt-12">
      <div className="max-w-6xl mx-auto px-4 py-10 md:py-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* About the Company Section */}
        <div>
          <h2 className="font-bold text-lg mb-2">Pehell</h2>
          <p className="text-gray-400 text-sm">
            Founded by Kunal Shah, a visionary with a background in mechanical engineering and product design, Pehell is an Indian company driven by a singular mission: to produce innovative kids' development products that foster engagement and readiness in toddlers and young children.
          </p>
          <div className="flex mt-4">
            <a href="#" target="_blank" rel="noreferrer" className="mr-2">
              <i className='bi bi-facebook'></i>
            </a>
            <a href="#" target="_blank" rel="noreferrer" className="mr-2">
              <i className='bi bi-twitter'></i>
            </a>
            <a href="https://www.instagram.com/pehell.in?igsh=YnZzMjRsOWFyNmd1" target="_blank" rel="noreferrer">
              <i className='bi bi-instagram'></i>
            </a>
          </div>
        </div>

        {/* Quick Links Section */}
        <div>
          <h2 className="font-bold text-lg mb-2">Quick Links</h2>
          <ul>
            <li><Link to="/about-us" className="text-gray-400 hover:text-white transition duration-300">About us</Link></li>
            <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white transition duration-300">Privacy Policy</Link></li>
            <li><Link to="/term-condition" className="text-gray-400 hover:text-white transition duration-300">Term Condition</Link></li>
            <li><Link to="/contact" className="text-gray-400 hover:text-white transition duration-300">Contact</Link></li>
          </ul>
        </div>

        {/* Contact Us Section */}
        <div>
          <h2 className="font-bold text-lg mb-2">Contact Us</h2>
          <p className='text-gray-400 pb-4'>
            Address: Om Shree Vrindavan Apartment, Beside Ram Kutir Soc, Ramdev Park, Mira-Rd East, Mumbai-401107
          </p>
          <p className="text-gray-400">
            Email: contact@pehell.com<br />
            Phone: +91 7756048751, +91 7718006913
          </p>
        </div>

        {/* Newsletter Section */}
        <div>
          <h2 className="font-bold text-lg mb-2">Newsletter</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Your email address"
              className="text-gray-800 w-full p-2 mb-2"
              value={email}
              onChange={(e) => setEmail(e.target.email)}
            />
            <button
              type='submit'
              className="bg-btn hover:bg-blue-700 text-white px-4 py-2 rounded"
            >
              Subscribe
            </button>
            {msg && <p>{msg}</p>}
          </form>
        </div>
      </div>

      {/* Copyright and Design */}
      <div className="text-center text-gray-500 text-sm py-4 border-t border-gray-700">
        © {new Date().getFullYear()} PEHELL. All rights reserved.<br />
        Designed by <a href="https://arrivalsolutions.in/" target="_blank" rel="noopener noreferrer">Arrival Solutions.</a>
      </div>
    </footer>
  );
};

export default Footer;
