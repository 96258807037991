import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useHistory, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import AuthContext from '../context/AuthContext';

const MyProduct = () => {
  const { addToCart } = useContext(AuthContext);

  const { id } = useParams();

  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/${id}`);
        setProduct(response.data);
        console.log(response)
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    const fetchRelatedProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product`);
        const products = response.data;
        const filteredProducts = products.filter(p => p.id !== parseInt(id));
        setRelatedProducts(filteredProducts.slice(0, 3)); // Show only three related products
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    if (id) {
      fetchProduct();
      fetchRelatedProducts();
    }
  }, [id]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleAddToCart = (productId) => {
    addToCart(productId, 1); // Add one quantity of the product to the cart
   
  };

  return (
    <div className="container mx-auto mt-8 px-4 md:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex justify-center items-center">
          <Carousel showArrows={true} showThumbs={false} infiniteLoop={true} autoPlay={true}>
            {product.images.map((image, index) => (
              <div key={index} className="carousel-image">
                <img
                  src={image}
                  alt={product.name}
                  className="rounded-lg shadow-md object-cover"
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div>
          <h1 className="text-3xl font-semibold mb-4 text-black">{product.name}</h1>
          <p className="text-gray-600 mb-4">{product.description}</p>
          <p className="text-gray-800 font-semibold mb-4">Price: {product.price}</p>
          <button className="bg-btn hover:bg-orange-600 text-white py-3 px-6 rounded-lg shadow-lg mx-4" 
                  onClick={() => handleAddToCart(product._id)}
          >
            Add to Cart
          </button>
         
        </div>
      </div>

      <h2 className="text-2xl font-semibold mt-8 mb-4 text-black">Related Products</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4" style={{ cursor: "pointer" }}>
      {relatedProducts.map((product) => (
          <motion.div
            key={product._id}
            className="bg-white rounded-lg shadow-md overflow-hidden"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            <Carousel showArrows={true} showThumbs={false} infiniteLoop={true} autoPlay={false}>
              {product.images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={product.name}
                    className="w-full h-48 object-cover"
                  />
                </div>
              ))}
            </Carousel>
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2">{product.name}</h2>
              <div className="mt-4 text-right">
                <button 
                  className="bi bi-cart3 text-lg font-extrabold bg-btn hover:bg-orange-600 text-white py-2 px-6 rounded-lg shadow-lg"
                  onClick={() => handleAddToCart(product._id)}
                >
            
                </button>
                <Link key={product._id} to={`/product/${product._id}`}>
                  <i className="bi bi-eye text-lg font-extrabold bg-btn hover:bg-orange-600 text-white py-2 px-6 rounded-lg shadow-lg mx-3"></i>
                </Link>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default MyProduct;
