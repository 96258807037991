import React, { useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = () => {
  const navigate = useNavigate();
  const { cartItem } = useContext(AuthContext) || { cartItem: [] };
  console.log(cartItem)
  const [shipping, setShipping] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    postalCode: ''
  });
  const [billing, setBilling] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    postalCode: ''
  });

  const totalAmount = cartItem.reduce((acc, item) => acc + (item.product.price * item.quantity), 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkout`, {
        shipping,
        billing,
        cartItems: cartItem,
        totalAmount
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`
        }
      });

      if (res.status === 201) {
        navigate('/confirmation', { state: { orderData: res.data } });
      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto mt-8 px-4 md:px-8">
      <h1 className="text-3xl font-semibold mb-4">Checkout</h1>
      <form onSubmit={handleSubmit}>
        <h2 className="text-2xl font-semibold mb-4">Shipping Information</h2>
        <input type="text" value={shipping.name} onChange={(e) => setShipping({ ...shipping, name: e.target.value })} placeholder="Name" required />
        <input type="email" value={shipping.email} onChange={(e) => setShipping({ ...shipping, email: e.target.value })} placeholder="Email" required />
        <input type="text" value={shipping.phone} onChange={(e) => setShipping({ ...shipping, phone: e.target.value })} placeholder="Phone" required />
        <input type="text" value={shipping.address} onChange={(e) => setShipping({ ...shipping, address: e.target.value })} placeholder="Address" required />
        <input type="text" value={shipping.city} onChange={(e) => setShipping({ ...shipping, city: e.target.value })} placeholder="City" required />
        <input type="text" value={shipping.state} onChange={(e) => setShipping({ ...shipping, state: e.target.value })} placeholder="State" required />
        <input type="text" value={shipping.postalCode} onChange={(e) => setShipping({ ...shipping, postalCode: e.target.value })} placeholder="Postal Code" required />

        <h2 className="text-2xl font-semibold mb-4 mt-6">Billing Information</h2>
        <input type="text" value={billing.name} onChange={(e) => setBilling({ ...billing, name: e.target.value })} placeholder="Name" required />
        <input type="email" value={billing.email} onChange={(e) => setBilling({ ...billing, email: e.target.value })} placeholder="Email" required />
        <input type="text" value={billing.phone} onChange={(e) => setBilling({ ...billing, phone: e.target.value })} placeholder="Phone" required />
        <input type="text" value={billing.address} onChange={(e) => setBilling({ ...billing, address: e.target.value })} placeholder="Address" required />
        <input type="text" value={billing.city} onChange={(e) => setBilling({ ...billing, city: e.target.value })} placeholder="City" required />
        <input type="text" value={billing.state} onChange={(e) => setBilling({ ...billing, state: e.target.value })} placeholder="State" required />
        <input type="text" value={billing.postalCode} onChange={(e) => setBilling({ ...billing, postalCode: e.target.value })} placeholder="Postal Code" required />

        <div className="mt-6">
          <h2 className="text-2xl font-semibold mb-4">Order Summary</h2>
          {cartItem.map((item) => (
            <div key={item._id} className="mb-4">
              <p>{item.product.name} x {item.quantity}</p>
              <p>Price: ${item.product.price * item.quantity}</p>
            </div>
          ))}
          <h3 className="text-xl font-semibold">Total Amount: ${totalAmount}</h3>
        </div>

        <button type="submit" className="mt-6 w-full rounded-md bg-blue-500 py-2 font-medium text-blue-50 hover:bg-blue-600">Place Order</button>
      </form>
    </div>
  );
};

export default CheckoutForm;
