import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';

const Profile = () => {
    const { user, logout } = useContext(AuthContext);

    return (
        <div className='container col-xl-6 mx-auto'>
            {user ? (
                <>
                    <h1 className='mb-3'>Profile</h1>
                    <hr/>
                    <p> <b>Name:</b> {user.name}</p>
                    <p> <b>Email:</b> {user.email}</p>
                    <p> <b>Phone:</b> {user.phone}</p>
                    <p> <b>User Role :</b> {user.role}</p>
                    <hr/>
                    <button onClick={logout}>Logout</button>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default Profile;
