import React, { useState } from 'react';

const Questionnaire = () => {
  const [answers, setAnswers] = useState({
    age: '',
    skill: '',
    interest: '',
    space: '',
    budget: ''
  });

  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAnswers(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.arrivalsoutions.in/questionnaire.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(answers)
      });

      const responseData = await response.json();

      if (response.ok) {
        alert('Questionnaire data submitted successfully.');
        // Fetch products based on the user's answers
        fetchProducts();
        // Reset form after successful submission
        setAnswers({
          age: '',
          skill: '',
          interest: '',
          space: '',
          budget: ''
        });
      } else {
        console.error('Failed to submit questionnaire data:', responseData.message || response.statusText);
      }
    } catch (error) {
      console.error('Error submitting questionnaire data:', error.message);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://api.arrivalsoutions.in/product.php');
      const productData = await response.json();
      // Filter products based on user's answers (example logic, adjust as needed)
      const filteredProducts = productData.filter(product => 
        product.age <= answers.age && product.skill === answers.skill
      );
      setProducts(filteredProducts);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching products:', error.message);
    }
  };

  const renderQuestionSection = (title, name, options) => (
    <div key={name}>
      <h2 className="font-semibold text-lg">{title}</h2>
      <select
        name={name}
        onChange={handleInputChange}
        value={answers[name]}
        className="mt-2 block w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >
        <option value="">Select</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );

  const questionSections = [
    {
      title: "What age is your child, and what stage of development are they currently in?",
      name: "age",
      options: [
        { value: "0-1", label: "0-1 yrs" },
        { value: "1-2", label: "1-2 yrs" },
        { value: "2-3", label: "2-3 yrs" },
        { value: "3-4", label: "3-4 yrs" },
        { value: "4-5", label: "4-5 yrs" },
        { value: "above-5", label: "Above 5 yrs" }
      ]
    },
    {
      title: "What specific skills or areas of development are you hoping to support or enhance with the toy?",
      name: "skill",
      options: [
        { value: "fine-motor", label: "Fine Motor Skills" },
        { value: "cognitive", label: "Cognitive Development" },
        { value: "gross-motor", label: "Gross Motor Skills" },
        { value: "speech-language", label: "Speech & Language" },
        { value: "social-emotional", label: "Social & Emotional Skills" },
        { value: "all", label: "All Of The Above" }
      ]
    },
    {
      title: "What are your child's interests and preferences?",
      name: "interest",
      options: [
        { value: "crawling", label: "Crawling" },
        { value: "colours", label: "Colours Attraction" },
        { value: "music", label: "Loves Music" }
      ]
    },
    {
      title: "How much space do you have available for play and storage?",
      name: "space",
      options: [
        { value: "0-1", label: "0-1 Meter" },
        { value: "1-2", label: "1-2 Meters" },
        { value: "2-3", label: "2-3 Meters" }
      ]
    },
    {
      title: "What is your budget for the toy?",
      name: "budget",
      options: [
        { value: "0-500", label: "0 To 500" },
        { value: "500-1000", label: "500 To 1000" },
        { value: "1000-1500", label: "1000 To 1500" },
        { value: "1500-2000", label: "1500 To 2000" }
      ]
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold text-center mb-6">Child Development Questionnaire</h1>
      
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          {questionSections.map(section =>
            renderQuestionSection(section.title, section.name, section.options)
          )}
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </form>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-md w-full max-w-2xl relative">
            <h2 className="text-xl font-bold mb-4">Recommended Products</h2>
            <button className="absolute top-2 right-2 text-red-500" onClick={() => setShowModal(false)}>Close</button>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {products.map(product => (
                <div key={product.id} className="border p-4 rounded-md">
                  <h3 className="font-semibold text-lg">{product.name}</h3>
                  <p>{product.desc}</p>
                  <p><strong>Price:</strong> {product.price}</p>
                  <div className="flex space-x-2 mt-2">
                    {product.images.map((image, index) => (
                      <img key={index} src={image} alt={product.name} className="w-16 h-16 object-cover" />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Questionnaire;