import logo from './logo.svg';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Profile from './components/Profile';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Home from './components/Home';
import Nav from './Layout/Nav';
import Product from './components/Product';
import MyProduct from './components/Shop';
import Cart from './components/Cart';
import CheckoutForm from './components/CheckoutForm';
import OrderList from './components/Myorder';
import ViewOrder from './components/OrderDetails';
import ContactForm from './components/Contact';
import About from './components/About';
import ProductList from './components/AgeFilter';
import Questionnaire from './components/Parrenthood';
import Footer from './Layout/Footer';
import PrivacyPolicy from './components/Privacy';
import TermsConditions from './components/Term';
import Confirm from './components/Confirm';
import ConfirmationPage from './components/Confirm';
import ScrollToTop from './ScrollToTop';


function App() {
  return (
    <div>
      <AuthProvider>
        <BrowserRouter>
          <Nav />
          <ScrollToTop/>
          <Routes>

            <Route path='/' element={<Home />} />
            <Route path='/about-us' element={<About/>} />
            <Route path='/contact' element={<ContactForm/>} />
            <Route path='/agefilter/:size' element={<ProductList/>} />


            <Route path='/signin' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/forgot' element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            <Route path='products' element={<Product/>} />
            <Route path='product/:id' element={<MyProduct/>} />
            <Route path='/cart' element={<Cart/>} />
            <Route path='/checkout' element={<CheckoutForm/>} />
            <Route path='/confirmation' element ={<ConfirmationPage/>} />
            <Route path='myorder' element={<OrderList/>} />
            <Route path='/order-details/:id' element={<ViewOrder/>} />
            <Route path='/parrenthood' element={<Questionnaire/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            <Route path='/term-condition' element={<TermsConditions/>} />
          </Routes>
          <Footer/>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
