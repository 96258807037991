
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from './../context/AuthContext';

const ResetPassword = () => {
    const { token, authErr, authMsg } = useParams();
    const [password, setPassword] = useState('');
    const { resetPassword } = useContext(AuthContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        resetPassword(token, password);
    };

    return (
        <div>
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
                {authMsg && (
                            <p className='text-xl'>{authMsg}</p>
                        )}
                        {authErr && (
                            <p className='text-xl text-red-500'>{typeof authErr === 'string' ? authErr : authErr.message}</p>
                        )}
            </form>
        </div>
    );
};

export default ResetPassword;
