import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'; // Use 'react-router-dom' for navigation

const OrderList = () => {
  const navigate = useNavigate(); // Use 'useNavigate' for navigation
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/vieworder`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('token')}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Orders</h1>
      {orders.map((order) => (
        <div key={order._id} className="bg-white shadow-md rounded-lg p-6 mb-4">
          <h2 className="text-xl font-semibold mb-2">Order ID: {order._id}</h2>
          <h3 className="text-lg font-semibold mb-2">Shipping Details</h3>
          <p><strong>Name:</strong> {order.shipping?.name}</p>
          <p><strong>Email:</strong> {order.shipping.email} <span className='ms-14'><strong>Phone:</strong> {order.shipping.phone}</span> </p>
          <p><strong>Address:</strong> {order.shipping.address} <span className='ms-14'><strong>City:</strong> {order.shipping.city}</span> <span className='ms-14'><strong>State:</strong> {order.shipping.state}</span> <span className='ms-14'><strong>Postal Code:</strong> {order.shipping.postalCode}</span></p>
         

          <div >
            <h4 className="text-lg font-semibold mb-2">Order Summary</h4>

            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>QTY</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {order.cartItems.map((item) => (
                  <tr key={item._id}>
                    <td> {item.product?.name}</td>
                    <td>{item?.quantity}</td>
                    <td>{item.product?.price}</td>
                  </tr>

                ))}
                <tr>
                  <th colSpan="2">Total Amount:</th>
                  <td> {order.totalAmount}</td>
                </tr>
              </tbody>
            </table>


          </div>

          <div className='text-right'>
            <button
          
              className='mt-6  rounded-md bg-blue-500 py-2 px-8 font-medium text-blue-50 hover:bg-blue-600 '
            >
             Download
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderList;
