import React from 'react';
import { useNavigate } from 'react-router-dom';
import ImageGallery from './ImageGallery';
import Product from './Product';
import Store from './Store';

const Home = () => {
    const navigate = useNavigate();

    return (
        <>

            <div className="relative h-screen flex items-center justify-center overflow-hidden">
                {/* Background Image */}
                <div className="absolute inset-0 z-0 fadeIn">
                    <img
                        src="https://template.hasthemes.com/toyqo/toyqo/assets/images/slider/slider2-1.jpg"
                        alt="Background"
                        className="object-cover w-full h-full opacity-80 animate-background"
                    />
                </div>

                {/* Overlay */}
                <div className="absolute inset-0 bg-black opacity-50 z-10"></div>

                {/* Content */}
                <div className="z-20 text-center text-white">
                    <h1 className="text-2xl md:text-5xl mb-4 font-gabriela">
                        Welcome to Our Kids&apos; Store
                    </h1>
                    <p className="text-lg md:text-xl mb-4 font-gabriela">
                        Explore our fun and colorful range of toys and furniture!
                    </p>
                    <button
                        className="bg-btn-intro text-white py-3 px-6 mx-6 rounded-lg shadow-lg animated-button"
                        onClick={() => navigate('/products')}
                    >
                        <i className="bi bi-cart4"></i> Shop Now
                    </button>

                    <button
                        className="bg-btn-intro text-white py-3 px-6 rounded-lg shadow-lg animated-button"
                        onClick={() => navigate('/parrenthood')}
                    >
                        <i className="bi bi-cart4"></i> Parent Hood
                    </button>
                </div>
            </div>

            <ImageGallery />

            <Store />
        </>
    );
};

export default Home;
