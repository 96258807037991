import React, { useState, useContext } from 'react';
import AuthContext from '../context/AuthContext';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const { forgotPassword, authMsg, authErr } = useContext(AuthContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        forgotPassword(email);
    };

    return (
        <section className="py-26 bg-white">
            <div className="container px-4 mx-auto">
                <div className="max-w-lg mx-auto py-8">
                    <div className="text-center mb-8">
                        <h2 className="text-3xl md:text-4xl font-extrabold mb-2">Forgot Password</h2>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 font-extrabold" htmlFor="email">Email</label>
                            <input
                                className="inline-block w-full p-4 leading-6 text-lg font-extrabold placeholder-indigo-900 bg-white shadow border-2 border-indigo-900 rounded"
                                type="email"
                                id="email"
                                placeholder="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <button
                            className="inline-block w-full py-4 px-6 mb-6 text-center text-lg leading-6 text-white font-extrabold bg border-3 border-indigo-900 shadow rounded transition duration-200"
                            type="submit"
                            style={{
                                background: "#25BCCC"
                            }}
                        >
                            Submit
                        </button>
                        {authMsg && (
                            <p className='text-xl'>{authMsg}</p>
                        )}
                        {authErr && (
                            <p className='text-xl text-red-500'>{typeof authErr === 'string' ? authErr : authErr.message}</p>
                        )}
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ForgotPassword;
