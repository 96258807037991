import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopBar from './TopBar';
import AuthContext from '../context/AuthContext';
import Cookies from 'js-cookie';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      return;
    }

    // Make an API call to fetch search results
    fetch(`https://api.arrivalsoutions.in/product.php?search=${searchQuery}`)
      .then(response => response.json())
      .then(data => {
        setSearchResults(data);
      })
      .catch(error => {
        console.error('Error fetching search results:', error);
      });
  }, [searchQuery]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

  const handleSearchBlur = () => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
    }
  };

  const handleUserIconClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    logout();
    Cookies.remove('token');
    navigate('/login');
  };

  useEffect(()=>{
    setIsOpen(false)
  },[location])

  useEffect(() => {
    setIsDropdownOpen(false); // Close the dropdown when the route changes
  }, [location]);

  return (
    <header className="bg-white shadow-md">
      <TopBar />
      <div className="container mx-auto flex items-center justify-between py-4 px-6 lg:px-20">
        <div className="flex items-center">
          <div className="text-3xl font-bold text-orange-500">
            <Link to="/">
              <img src="https://cdn.arrivalsolutions.in/logo/Asset%2013pehell%20logo_.png" alt="Pehell.com" width={140} height={55} />
            </Link>
          </div>
        </div>
        <nav className="hidden md:flex space-x-4 text-xl item-center lg:pe-24">
          <Link to="/" className="text-gray-700 hover:text-gray-900">Home</Link>
          <Link to="/products" className="text-gray-700 hover:text-gray-900">Products</Link>
          <Link to="/about-us" className="text-gray-700 hover:text-gray-900">About</Link>
          <Link to="/contact" className="text-gray-700 hover:text-gray-900">Contact</Link>
        </nav>
        <div className="flex items-center space-x-4">
          {/* <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              onBlur={handleSearchBlur}
              className="text-gray-700 border rounded-md px-3 py-1 focus:outline-none focus:ring focus:border-blue-300"
            />
            {searchResults.length > 0 && (
              <div className="absolute bg-black text-white rounded-md mt-1 w-full z-10">
                <ul>
                  {searchResults.map(product => (
                    <li key={product.id}>
                      <Link to={`/shop/${product.id}`}>
                        {product.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div> */}

          <Link to="/cart" className="text-gray-700 hover:text-gray-900">
            <i className="bi bi-cart3 text-lg font-extrabold"></i>
          </Link>
          {isAuthenticated ? (
            <div className="relative">
              <i 
                className="bi bi-person text-xl font-extrabold cursor-pointer text-red-700" 
                onClick={handleUserIconClick}
              ></i>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-20">
                  <Link to="/profile" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Profile</Link>
                  <Link to="/myorder" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">My Order</Link>
                  <button 
                    onClick={handleLogout} 
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/signin" className="text-gray-700 hover:text-gray-900">
              <i className="bi bi-person text-xl font-extrabold cursor-pointer"></i>
            </Link>
          )}
          <button 
            className="md:hidden text-gray-700 hover:text-gray-900"
            onClick={() => setIsOpen(!isOpen)}
          >
            <i className="bi bi-justify text-lg font-extrabold"></i>
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="md:hidden bg-white shadow-md">
          <Link to="/" className="block px-4 py-2 text-gray-700 hover:text-gray-900">Home</Link>
          <Link to="/products" className="block px-4 py-2 text-gray-700 hover:text-gray-900">Products</Link>
          <Link to="/about-us" className="block px-4 py-2 text-gray-700 hover:text-gray-900">About</Link>
          <Link to="/contact" className="block px-4 py-2 text-gray-700 hover:text-gray-900">Contact</Link>
        </nav>
      )}
    </header>
  );
};

export default Header;
