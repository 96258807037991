import React from 'react';

const TermsConditions = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 text-black">
      <h1 className="text-3xl font-bold mb-4">Terms & Conditions</h1>
      <p className="mb-4">
        Welcome to Pehell! These Terms & Conditions govern your use of our website, products, and services. By accessing or using any part of our website, you agree to be bound by these Terms & Conditions. Please read them carefully before proceeding.
      </p>
      <h2 className="text-xl font-bold mb-2">1. Use of Website:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>You must be at least 18 years old or have parental consent to use our website.</li>
        <li>You agree not to use our website for any unlawful purpose or in violation of any applicable laws or regulations.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">2. Product Descriptions:</h2>
      <p className="mb-4">
        We strive to provide accurate and up-to-date product descriptions, but we do not warrant that product descriptions or other content on our website are error-free, complete, or current.
      </p>
      <h2 className="text-xl font-bold mb-2">3. Pricing and Payment:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>All prices are listed in Indian Rupees (INR) and are subject to change without notice.</li>
        <li>Payment must be made in full at the time of purchase. We accept various payment methods, including credit/debit cards, net banking, and other online payment options.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">4. Order Acceptance:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>Your order constitutes an offer to purchase our products. We reserve the right to accept or reject any order at our discretion.</li>
        <li>If we are unable to fulfill your order for any reason, we will notify you and issue a refund for the full purchase price.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">5. Shipping and Delivery:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>We strive to ship orders promptly, but delivery times may vary depending on your location and other factors beyond our control.</li>
        <li>Shipping costs and estimated delivery times will be provided at the time of checkout.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">6. Returns and Refunds:</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>We offer a hassle-free return policy. If you are not satisfied with your purchase, you may return it within 7 working days for a full refund or exchange, provided the product is unused and in its original condition.</li>
        <li>Refunds will be issued to the original method of payment within 7 working days of receiving the returned product.</li>
      </ul>
      <h2 className="text-xl font-bold mb-2">7. Intellectual Property:</h2>
      <p className="mb-4">
        All content on our website, including text, graphics, logos, images, and software, is the property of Pehell and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written consent.
      </p>
      <h2 className="text-xl font-bold mb-2">8. Limitation of Liability:</h2>
      <p className="mb-4">
        In no event shall Pehell be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our website or products.
      </p>
      <h2 className="text-xl font-bold mb-2">9. Governing Law:</h2>
      <p className="mb-4">
        These Terms & Conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising under these Terms & Conditions shall be subject to the exclusive jurisdiction of the courts in India.
      </p>
      <h2 className="text-xl font-bold mb-2">10. Changes to Terms & Conditions:</h2>
      <p>
        We reserve the right to update or modify these Terms & Conditions at any time without prior notice. Changes will be effective immediately upon posting on our website.
      </p>
      <p className="mt-4">
        If you have any questions or concerns about these Terms & Conditions, please contact us at <a href="mailto:contact@pehell.com">contact@pehell.com</a>.
      </p>
    </div>
  );
};

export default TermsConditions;
