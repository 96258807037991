import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderData = location.state?.orderData;

  if (!orderData) {
    // Redirect to home or checkout if no order data is available
    navigate('/');
    return null;
  }

  return (
    <div className="container mx-auto mt-8 px-4 md:px-8">
      <h1 className="text-3xl font-semibold mb-4">Order Confirmation Done</h1>
      
    </div>
  );
};

export default ConfirmationPage;
