import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom'; // Use 'react-router-dom' for routing

const ViewOrder = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/vieworder/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('token')}`,
          },
        });
        setOrder(response.data);
      } catch (error) {
        setError('Error fetching order details');
        console.error('Error fetching order:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!order) return <div>No order found</div>;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">
        Order Details{' '}
        <span style={{ marginLeft: '56px', color: '#25bccc' }}>
          Order Id : {id}
        </span>
      </h1>

      <table className="border-collapse border-2 border-gray-500" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th className="border border-gray-400 px-4 py-2 text-gray-800">Product Summary</th>
            <th className="border border-gray-400 px-4 py-2 text-gray-800">Shipping</th>
            <th className="border border-gray-400 px-4 py-2 text-gray-800">Billing</th>
          </tr>
        </thead>
        <tbody>
          {order.cartItems.map((item) => (
            <tr key={item._id}>
              <td className="border border-gray-400 px-4 py-2">
                <img src={item.product.images[0]} alt={item.product.name} className="w-24 h-24 object-cover mb-2" />
                Product: {item.product.name} <br />
                ₹ : {item.product.price} <br />
                QTY : {item.quantity}
              </td>

              <td className="border border-gray-400 px-4 py-2">
                <p><strong>Name:</strong> {order.shipping.name}</p>
                <p><strong>Email:</strong> {order.shipping.email}</p>
                <p><strong>Phone:</strong> {order.shipping.phone}</p>
                <p><strong>Address:</strong> {order.shipping.address}</p>
                <p><strong>City:</strong> {order.shipping.city}</p>
                <p><strong>State:</strong> {order.shipping.state}</p>
                <p><strong>Postal Code:</strong> {order.shipping.postalCode}</p>
              </td>

              <td className="border border-gray-400 px-4 py-2">
                <p><strong>Name:</strong> {order.billing.name}</p>
                <p><strong>Email:</strong> {order.billing.email}</p>
                <p><strong>Phone:</strong> {order.billing.phone}</p>
                <p><strong>Address:</strong> {order.billing.address}</p>
                <p><strong>City:</strong> {order.billing.city}</p>
                <p><strong>State:</strong> {order.billing.state}</p>
                <p><strong>Postal Code:</strong> {order.billing.postalCode}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewOrder;
