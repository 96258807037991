import React, { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom';

export default function SignIn() {

  const { login, authMsg, authErr } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <section className="py-26 bg-white">
      <div className="container px-4 mx-auto">
        <div className="max-w-lg mx-auto py-8">
          <div className="text-center mb-8">
            <h2 className="text-3xl md:text-4xl font-extrabold mb-2">Sign in</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block mb-2 font-extrabold" htmlFor="email">Email</label>
              <input
                className="inline-block w-full p-4 leading-6 text-lg font-extrabold placeholder-indigo-900 bg-white shadow border-2 border-indigo-900 rounded"
                type="email"
                id="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 font-extrabold" htmlFor="password">Password</label>
              <input
                className="inline-block w-full p-4 leading-6 text-lg font-extrabold placeholder-indigo-900 bg-white shadow border-2 border-indigo-900 rounded"
                type="password"
                id="password"
                placeholder="**********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex flex-wrap -mx-4 mb-6 items-center justify-between">
              <div className="w-full lg:w-auto px-4 mb-4 lg:mb-0">
                <label htmlFor="rememberMe">
                  <input type="checkbox" id="rememberMe" />
                  <span className="ml-1 font-extrabold">Remember me</span>
                </label>
              </div>
              <div className="w-full lg:w-auto px-4">
                <Link className="inline-block font-extrabold hover:underline" to="/forgot">Forgot your password?</Link>
              </div>
            </div>
            <button
              className="inline-block w-full py-4 px-6 mb-6 text-center text-lg leading-6 text-white font-extrabold bg border-3 border-indigo-900 shadow rounded transition duration-200"
              type="submit"
              style={{ background: "#25BCCC" }}
            >
              Sign in
            </button>
            {authErr && (
              <p className="text-center text-xl font-extrabold  text-red-500">
                {typeof authErr === 'string' ? authErr : authErr.message}
              </p>
            )}
            <p className="text-center font-extrabold">
              Don&rsquo;t have an account?{' '}
              <Link to="/signup" className="text-red-500 hover:underline">
                Sign up
              </Link>
            </p>
            
          </form>
        </div>
      </div>
    </section>
  );
}
